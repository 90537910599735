// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculator-js": () => import("./../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-qr-js": () => import("./../src/pages/qr.js" /* webpackChunkName: "component---src-pages-qr-js" */),
  "component---src-templates-guide-index-jsx": () => import("./../src/templates/Guide/index.jsx" /* webpackChunkName: "component---src-templates-guide-index-jsx" */),
  "component---src-templates-mode-index-jsx": () => import("./../src/templates/Mode/index.jsx" /* webpackChunkName: "component---src-templates-mode-index-jsx" */),
  "component---src-templates-therapy-index-jsx": () => import("./../src/templates/Therapy/index.jsx" /* webpackChunkName: "component---src-templates-therapy-index-jsx" */)
}

